import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import Layout from "../layout/Layout.vue";
import LayoutHome from "../layout/LayoutHome.vue";
import LayoutClinic from "../layout/LayoutClinic.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "forgetPassword",
        name: "forgetPassword",
        meta: { title: "忘记密码" },
        component: () =>
          import(
            /* webpackChunkName: 'forgetPassword'*/ "@/views/Login/forgetPassword.vue"
          ),
      },
      {
        path: "fixPassword",
        name: "fixPassword",
        meta: { title: "修改密码" },
        component: () =>
          import(
            /* webpackChunkName: 'fixPassword'*/ "@/views/Login/fixPassword.vue"
          ),
      },
      {
        path: "clinical",
        name: "clinical",
        meta: { title: "中医临床" },
        component: () =>
          import(
            /* webpackChunkName: 'orderlist'*/ "@/views/clinical/index.vue"
          ),
      },
      {
        path: "clinical2",
        name: "clinical2",
        meta: { title: "中西医结合" },
        component: () =>
          import(
            /* webpackChunkName: 'orderlist'*/ "@/views/clinical2/index.vue"
          ),
      },
      {
        path: "channels",
        name: "channels",
        meta: { title: "中医AI" },
        component: () =>
          import(
            /* webpackChunkName: 'channels'*/ "@/views/channels/index.vue"
          ),
      },
      {
        path: "slow",
        name: "slow",
        meta: { title: "慢性疾病" },
        component: () =>
          import(
            /* webpackChunkName: 'channels'*/ "@/views/slow/index.vue"
          ),
      },
      {
        path: "physique",
        name: "physique",
        meta: { title: "体质辨识" },
        component: () =>
          import(
            /* webpackChunkName: 'physique'*/ "@/views/physique/index.vue"
          ),
      },
      {
        path: "covid19",
        name: "covid19",
        meta: { title: "新冠专题" },
        component: () =>
          import(/* webpackChunkName: 'covid19'*/ "@/views/covid19/index.vue"),
      },
      {
        path: "coronavirus",
        name: "coronavirus",
        meta: { title: "新冠专题" },
        component: () =>
          import(
            /* webpackChunkName: 'channels'*/ "@/views/coronavirus/index.vue"
          ),
      },
      {
        path: "programme",
        name: "programme",
        meta: { title: "新冠专题" },
        component: () =>
          import(
            /* webpackChunkName: 'channels'*/ "@/views/coronavirus/programme.vue"
          ),
      },
      {
        path: "clinic",
        name: "clinic",
        meta: { title: "诊所入驻申请" },
        component: () =>
          import(/* webpackChunkName: 'clinic'*/ "@/views/Login/clinic.vue"),
      },
      {
        path: "demonstrate",
        name: "demonstrate",
        meta: { title: "操作演示" },
        component: () =>
          import(
            /* webpackChunkName: 'physique'*/ "@/views/demonstrate/index.vue"
          ),
      },
      {
        path: "videoConsultation",
        name: "videoConsultation",
        meta: { title: "视频咨询" },
        component: () =>
          import(
            /* webpackChunkName: 'videoConsultation'*/ "@/views/videoConsultation/index.vue"
          ),
      },
      {
        path: "doctorVideo",
        name: "doctorVideo",
        meta: { title: "视频咨询" },
        component: () =>
          import(
            /* webpackChunkName: 'physique'*/ "@/views/doctorVideo/index.vue"
          ),
      },
      {
        path: "agora",
        name: "agora",
        meta: { title: "视频咨询" },
        component: () =>
          import(
            /* webpackChunkName: 'physique'*/ "@/views/doctorVideo/agora.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: LayoutHome,
    redirect: "/index",
    children: [
      {
        path: "home",
        name: "home",
        meta: { title: "首页" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/Home.vue"),
      },
      {
        path: "orderList",
        name: "orderList",
        meta: { title: "我的订单" },
        component: () =>
          import(/* webpackChunkName: 'orderlist'*/ "@/views/order/index.vue"),
      },
      {
        path: "diagnoseList",
        name: "diagnoseList",
        meta: { title: "我的就诊" },
        component: () =>
          import(
            /* webpackChunkName: 'diagnoseList'*/ "@/views/diagnose/index.vue"
          ),
      },
      {
        path: "diagnoseHistory",
        name: "diagnoseHistory",
        meta: { title: "就诊历史" },
        component: () =>
          import(
            /* webpackChunkName: 'diagnoseHistory'*/ "@/views/diagnose/history.vue"
          ),
      },
      {
        path: "userCenter",
        name: "userCenter",
        meta: { title: "个人中心" },
        component: () =>
          import(/* webpackChunkName: 'userCenter'*/ "@/views/user/index.vue"),
      },
      {
        path: "patient",
        name: "patient",
        meta: { title: "我的患者" },
        component: () =>
          import(/* webpackChunkName: 'userCenter'*/ "@/views/patient/index.vue"),
      },
    ],
  },
  {
    path: "/clinicManager",
    component: LayoutClinic,
    redirect: "/clinicManager/index",
    children: [
      {
        path: "index",
        name: "clinicManager",
        meta: { title: "诊所管理" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinic/index.vue"),
      },
      {
        path: "board",
        name: "board",
        meta: { title: "数据看板" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinic/board.vue"),
      },
      {
        path: "finance",
        name: "finance",
        meta: { title: "财务管理" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinic/finance.vue"),
      },
      {
        path: "doctor",
        name: "clinicDoctor",
        meta: { title: "医生列表" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinic/doctor.vue"),
      },
      {
        path: "order",
        name: "clinicOrder",
        meta: { title: "订单管理" },
        component: () =>
          import(/* webpackChunkName: 'home'*/ "@/views/clinicOrder/index.vue"),
      }
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: { title: "登录页面" },
    component: () =>
      import(/* webpackChunkName: 'login'*/ "@/views/Login/index.vue"),
  },
  {
    path: "/expertLogin",
    name: "expertLogin",
    meta: { title: "登录页面" },
    component: () =>
      import(
        /* webpackChunkName: 'expertLogin'*/ "@/views/Login/indexExpert.vue"
      ),
  },
  // {
  //   path: '*',
  //   redirect: '/index',
  // },
];

const router = new VueRouter({ routes });
router.beforeEach((to, from, next) => {
  store.commit("setPatientId", "");
  if (
    to.path !== "/login" &&
    to.path != "/forgetPassword" &&
    to.path != "/clinic" &&
    to.path != "/demonstrate" &&
    to.path != "/expertLogin"
  ) {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
    if (!userInfo.token) {
      next({ path: "/login" });
    } else {
      const bossRouterPathName = [
        'clinicManager', 'clinicDoctor', 'clinicOrder'
      ];
      // customerTypes，1：连锁诊所老板， 2：诊所老板， 3：医生
      const customerTypes = userInfo?.customerTypes;
      if (!customerTypes.includes(3)) {
        if (bossRouterPathName.indexOf(to.name) < 0) {
          store.commit("user/setIsLogin", true);
          next({ path: '/clinicManager/index' });
        }
        store.commit("user/setIsLogin", true);
        next();
      } else {
        store.commit("user/setIsLogin", true);
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
