/*
 * @Author: liz
 * @Date: 2023-03-05 20:07:39
 * @version:
 * @Descripttion:
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/clickOutside";
import "videojs-contrib-hls";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/styles/reset.css";

import moment from "moment";
import Print from "vue-print-nb";

import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import VideoPlayer from "vue-video-player";
import "@iconfu/svg-inject";
import * as echarts from 'echarts';
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

Vue.use(VideoPlayer);
Vue.use(Print);
Vue.use(ElementUI);
Vue.use(Viewer);

Viewer.setDefaults({
  title: false,
  toolbar: true,
});

Vue.config.productionTip = false;
Vue.prototype.$message = ElementUI.Message;
Vue.prototype.$moment = moment;
Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
